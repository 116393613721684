'use client'
import type { FC, ReactElement, ReactNode } from 'react'
import { useMemo } from 'react'
import type { FlexProps } from '@chakra-ui/react'
import { Flex, HStack } from '@chakra-ui/react'
import { usePathname } from 'next/navigation'
import { ContentContainer, GrandIconButton, GrandNextLink, HoverableComponent } from 'ui'
import { MenuItem, MenuItemComponent } from './MenuItem'

export interface TopNavContentProps extends FlexProps {
  menuItems: { other: MenuItem[] }
  logo: ReactNode
  onMenuHover: (isInside: boolean) => void
  onMenuToggle: (menuItems: MenuItem[]) => void
  toggleIcon: ReactElement
}

const TopNavContent: FC<TopNavContentProps> = ({
  onMenuHover,
  onMenuToggle,
  toggleIcon,
  menuItems,
  logo,
  ...props
}) => {
  const pathname = usePathname()

  const menuItemsFull = useMemo(() => {
    const mi = [...menuItems.other]
    // removes non-serializable values so we can pass then through extraArgs
    return mi.map(({ onClick, onHoverLeave, onHoverEnter, ...rest }) => ({
      ...rest
    }))
  }, [menuItems])

  const handleMenuToggle = () => {
    onMenuToggle(menuItemsFull)
  }

  return (
    <Flex w="100%" {...props}>
      <ContentContainer justify="center" overflow="hidden" py={0} spacing={0} w="full">
        <HoverableComponent
          alignItems="center"
          onHover={onMenuHover}
          display={{
            base: 'flex',
            xs: 'none'
          }}>
          <HStack align="center" flex={1} p={1} w="full">
            <Flex alignItems="center" flex={1}>
              {/* hamburger */}
              <GrandIconButton
                aria-label="open-menu"
                bg="none"
                color="primary"
                icon={toggleIcon}
                onClick={handleMenuToggle}
                shadow="none"
                size="sm"
              />
            </Flex>

            <GrandNextLink aria-label="home" cursor="pointer" href="/">
              {logo}
            </GrandNextLink>
          </HStack>
        </HoverableComponent>

        <HStack
          display={{
            base: 'none',
            xs: 'flex'
          }}
          fontWeight="medium"
          justify="space-between"
          py={0}
          textAlign="center"
          w="full">
          <HStack gap={4}>
            {menuItems.other.map((item) => (
              <MenuItemComponent
                key={item.id}
                {...item}
                isCurrent={pathname === item.path}
                text={item.text.toUpperCase()}
              />
            ))}
          </HStack>

          <Flex
            cursor="default"
            justifyContent={{
              base: 'center',
              lg: 'flex-start'
            }}>
            <GrandNextLink aria-label="home" cursor="pointer" href="/">
              {logo}
            </GrandNextLink>
          </Flex>
        </HStack>
      </ContentContainer>
    </Flex>
  )
}

export default TopNavContent
