'use client'
import { FC, ReactNode, useMemo } from 'react'
import granditudeLogo from '../../images/granditude_logo.png'
import granditudeLogoLight from '../../images/granditude_logo_light.svg'
import { MdClose, MdOutlineMenu } from 'react-icons/md'

import { useSelector } from 'shared-redux'
import { selectIsWindowOnTop } from 'shared-redux/state'
import { useModal, useSlideOut } from '#/src/custom/controllers'
import { FooterItem, RequiredPageProps, SocialMediaItem } from 'ecosystem'
import appConfig from '#/src/appConfig'
import { MenuItem, TopNavSpacer } from 'ui/storefront'
import { TopNav } from './custom-layout-components/TopNav/TopNav'
import { ImageBox } from 'ui'
import CustomFooter from '#/src/custom/layout/CustomFooter'
import { Box, LinkProps } from '@chakra-ui/react'
import { PageLayoutContainer } from 'storefront-modules/page'

export type CustomLayoutProps = RequiredPageProps & {
  children: ReactNode | ReactNode[]
  isDynamicLogoVisibility?: boolean
}

const CustomLayout: FC<CustomLayoutProps> = ({
  children,
  footerWidgets,
  staticPages,
  categories,
  isDynamicLogoVisibility
}) => {
  const { isHidden, closeSlideOut, openSlideOut, componentId } = useSlideOut()
  const { isHidden: isModalHidden, size: modalSize } = useModal()
  const isWindowOnTop = useSelector(selectIsWindowOnTop)

  // hardcoded links for transition period
  const staticLinks = useMemo(() => {
    return appConfig.staticLinks.length > 1
      ? appConfig.staticLinks
      : [
          { id: 'pricing', label: 'Pricing', path: '/pricing' },
          { id: 'blog', label: 'Stories', path: '/blog' },
          { id: 'contact-us', label: 'Contact us', path: '/contact-us' }
        ]
  }, [])

  const menuItems: {
    other: MenuItem[]
  } = useMemo(() => {
    return {
      other: [
        ...(staticPages
          ? staticPages.map((sp) => ({
              id: 'other-' + sp.id,
              text: sp.name,
              path: sp.slug ? `/${sp.slug}` : '/',
              onHoverEnter: () => closeSlideOut()
            }))
          : []),
        ...staticLinks.map(({ label, ...s }) => ({
          ...s,
          text: label,
          onHoverEnter: () => closeSlideOut()
        }))
      ]
    }
  }, [closeSlideOut, staticLinks, staticPages])

  const socialMediaItems: SocialMediaItem<LinkProps>[] = appConfig.socialMediaItems

  const footerItems: FooterItem[] = footerWidgets.map(({ widget }) => widget)

  const logoComponent = (
    <ImageBox
      h={10}
      imageProps={{
        title: 'logo',
        alt: 'logo',
        src: appConfig.logoUrl || granditudeLogo.src,
        style: {
          objectFit: 'contain'
        },
        quality: 100,
        priority: true,
        sizes: '10em'
      }}
      w={40}
    />
  )
  const logoLightComponent = (
    <ImageBox
      h={10}
      imageProps={{
        title: 'logo',
        alt: 'logo',
        src: granditudeLogoLight.src,
        style: {
          objectFit: 'contain'
        },
        quality: 100,
        priority: true,
        sizes: '10em'
      }}
      w={40}
    />
  )

  const handleOnMenuToggle = (menuItems: MenuItem[]) => {
    if (isHidden) {
      openSlideOut({
        title: '',
        componentId: 'mobileMenu',
        footerId: 'mobileMenu',
        extraArgs: {
          menuItems
        },
        drawerProps: null
      })
    } else {
      closeSlideOut()
    }
  }

  const handleOnMenuHover = (isInSide: boolean) => {
    if (isInSide && componentId === 'categories') {
      closeSlideOut()
    }
  }

  return (
    <PageLayoutContainer
      pageContextProps={{
        categories: categories || [],
        footerWidgets,
        staticPages
      }}
      position="relative">
      <TopNav
        contentHeight="8vh"
        logo={
          isHidden
            ? isDynamicLogoVisibility && isWindowOnTop
              ? null
              : logoLightComponent
            : logoComponent
        }
        onMenuHover={handleOnMenuHover}
        onMenuToggle={handleOnMenuToggle}
        toggleIcon={
          isHidden ? (
            <Box color="text.light">
              <MdOutlineMenu />
            </Box>
          ) : (
            <MdClose />
          )
        }
        zIndex={!isModalHidden && modalSize === 'full' ? 0 : 9000}
        {...{ menuItems, isHidden }}
      />
      <TopNavSpacer h="8vh" />
      {children}
      <CustomFooter logo={logoComponent} {...{ footerItems, socialMediaItems }} />
    </PageLayoutContainer>
  )
}

export default CustomLayout
