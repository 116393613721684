'use client'
import type { FC, MouseEvent } from 'react'
import { useEffect, useState } from 'react'
import { GrandNextLink, HoverableComponent } from 'ui'
import { HoverableTextUnderline } from './HoverableTextUnderline'

export interface MenuItem {
  id: string
  text: string
  path: string
  isCurrent?: boolean
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void
  other?: any[]
  onHoverEnter?: () => void
  onHoverLeave?: () => void
}

export const MenuItemComponent: FC<MenuItem> = ({
  onHoverEnter,
  onHoverLeave,
  text,
  path,
  isCurrent,
  onClick
}) => {
  const [isHovered, setIsHovered] = useState(false)

  useEffect(() => {
    if (isHovered) {
      onHoverEnter && onHoverEnter()
    } else {
      onHoverLeave && onHoverLeave()
    }
  }, [isHovered, onHoverEnter, onHoverLeave])

  return (
    <GrandNextLink
      aria-label={text}
      href={path}
      textDecoration="none!important"
      onClick={onClick}
      textStyle="landingHeading">
      <HoverableComponent
        borderRadius="sm"
        color="text.light"
        opacity={isCurrent ? 1 : 0.85}
        cursor="pointer"
        flexGrow={0}
        fontWeight="medium"
        onHover={setIsHovered}
        px={2}
        py={3}
        w="auto">
        <HoverableTextUnderline fontSize="xs" text={text.toUpperCase()} {...{ isHovered }} />
      </HoverableComponent>
    </GrandNextLink>
  )
}
