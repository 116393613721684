import type { FC } from 'react'
import type { StackProps } from '@chakra-ui/react'
import { VStack } from '@chakra-ui/react'
import { useOverrideComponentPropsContext } from 'ui/lib/override-component-props'
import { GrandDivider, GrandText } from 'ui'

export interface IHoverableTextUnderlineProps extends StackProps {
  text: string
  isHovered: boolean
}

export const HoverableTextUnderline: FC<IHoverableTextUnderlineProps> = (_props) => {
  const mergedProps = useOverrideComponentPropsContext('HoverableTextUnderline', _props)
  const { text, isHovered, ...props } = mergedProps

  return (
    <VStack cursor="pointer" fontSize="sm" spacing={0} {...props}>
      <GrandText cursor="inherit" isTruncated>
        {text}
      </GrandText>
      <GrandDivider
        borderColor={isHovered ? 'text.light' : 'transparent'}
        borderWidth={1}
        opacity={1}
        width={isHovered ? '100%' : 0}
      />
    </VStack>
  )
}
